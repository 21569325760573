import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

export const FirstTimersGuidePaForm = ({
  reCaptchaPublicKey,
  processForm,
  isUserVerified,
  setUserVerified,
  handleCaptchaExpiry,
  buttonText,
  checkHP,
  hasHPEntry,
}) => {
  const captchaRef = useRef();

  return (
    <>
      <h2>Unforgettable Experiences</h2>
      <a
        className="btn"
        href="https://events.circuitree.com/campwoodward/Registration/EventSelection/3/?filter=Locations%7C5%5EEventTypes%7C7"
      >
        BOOK NOW
      </a>
    </>
  );
};

FirstTimersGuidePaForm.propTypes = {
  reCaptchaPublicKey: PropTypes.string.isRequired,
  processForm: PropTypes.func.isRequired,
  setUserVerified: PropTypes.func.isRequired,
  isUserVerified: PropTypes.bool.isRequired,
  handleCaptchaExpiry: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  checkHP: PropTypes.func.isRequired,
  hasHPEntry: PropTypes.bool.isRequired,
};

FirstTimersGuidePaForm.defaultProps = {
  buttonText: null,
};
